import { h, Component } from "preact";
import { Router } from "preact-router";

import Header from "./header";
import Footer from './footer';
import Content from './content';

import Home from "../routes/home";

export default class App extends Component {
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  render() {
    return (
      <div id="app">
        <Header />
        <Content>
          <Router onChange={this.handleRoute}>
            <Home path="/" />
          </Router>
        </Content>
				<Footer />
      </div>
    );
  }
}
