import { h } from "preact";

const Content = ({ children }) => (
  <main className="content">
    <div className="container">
      {children}
    </div>
  </main>
);

export default Content;
