let logo = null;
// optional logo
try {
 logo = require('./assets/logo.png').default;
} catch (e) {}

export default {
  LOGO_URL: logo,
  HEADER_PICTURE_URL: process.env.HEADER_PICTURE_URL,
  ABOUT_US_TEXT: process.env.ABOUT_US_TEXT,
  PRACTICE_ADDRESS_LINE1: process.env.PRACTICE_ADDRESS_LINE1,
  PRACTICE_ADDRESS_LINE2: process.env.PRACTICE_ADDRESS_LINE2,
  PRACTICE_ADDRESS_LINE3: process.env.PRACTICE_ADDRESS_LINE3,
  PHONE_NUMBER: process.env.PHONE_NUMBER,
  TRADING_NAME: process.env.TRADING_NAME,
  ONLINE_BOOKING: process.env.ONLINE_BOOKING,
  BOOKING_WIDGET_URL: process.env.BOOKING_WIDGET_URL,
  bookingWidgetMountPointId: 'optimax-booking-widget',
}
