import { h } from "preact";
import { Link } from "preact-router/match";
import config from "../../config";

const Header = () => (
  <header className="header">
    <div className="container">
      <nav class="header__navbar navbar navbar-light bg-light">
        <Link className="header__logo-link navbar-brand" href="/">
          {!!config.LOGO_URL && (
            <img
              src={config.LOGO_URL}
              class="header__logo d-inline-block align-top"
              alt="logo"
              loading="lazy"
            />
          )}
        </Link>
        <div class="navigation nav">
          <Link className="navigation__link nav-link" activeClassName="navigation__link--active" href="/">
            Home
          </Link>
          <Link className="navigation__link nav-link" activeClassName="navigation__link--active" href="/covid">
            Covid
          </Link>
        </div>
      </nav>
    </div>
    <div className="header__image" style={{backgroundImage: `url(${config.HEADER_PICTURE_URL})`}} />
  </header>
);

export default Header;
