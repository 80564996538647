import { h } from "preact";
import { useMemo } from 'preact/hooks';
import config from "../../config";

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="footer">
      <p className="footer__content">
        Copyrights {config.TRADING_NAME} {currentYear}
      </p>
    </footer>
  );
};

export default Footer;
